import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { textanimation } from './Animation/index';
import backButton from "../../../assets/back.svg"
import BackButton from './Button/BackButton';



type Props = {
  setopenComponent: React.Dispatch<React.SetStateAction<number>>;
  formatCurrency: (homeWorth: string) => string;
  homeWorthval: string;
  setHomeWorthVal: React.Dispatch<string>;
  isReviewPage:boolean
};

const FivePageCompoThree = ({
  setopenComponent,
  formatCurrency,
  setHomeWorthVal,
  homeWorthval,
  isReviewPage
}: Props) => {
  const [errVal, setErrVal] = useState('');

  return (
    <>
      <BackButton setopenComponent = {setopenComponent} id = {2}/>

      <div className="pt-10">
        <motion.div variants={textanimation} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
          <p className="font-raleway mx-2 sm:mx-0 text-3xl md:text-4xl lg:text-5xl xl:text-7xl text-center font-black">
            Get a free home <span className='text-brand'>equity report </span>
            (no strings).
          </p>
        </motion.div>
        <motion.div variants={textanimation} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
          <p className="font-sans font-bold mx-2 sm:mx-0 text-sm sm:text-base md:text-xl lg:text-2xl xl:text-3xl text-center text-[#737373] mt-10">
            What is your home worth? (you can guess, it's OK).
          </p>
        </motion.div>
      </div>
      <div className="flex flex-col justify-center items-center mt-10">
        <div className="flex flex-row bg-white rounded-full  border-2 justify-center items-center">
          <div className="bg-brand ml rounded-full font-sans text-lg text-white px-8 py-2 sm:px-16">
            $
          </div>
          <input
            className="outline-none rounded-full font-sans text-lg pl-4 sm:px-16 py-2"
            type="text"
            placeholder="e.g-250,000"
            // min="4" 
            // max="9"
            // maxLength={12}
            value={homeWorthval}
            onChange={(e) => {
              setHomeWorthVal(e.target.value);
              setErrVal('');
            }}
          />
        </div>
        <span className="text-red-700 text-center sm:w-[65%] mx-auto my-4 text-[10px] sm:text-[12px] font-semibold">
          {errVal}
        </span>
        <div className="mt-5 mb-20 select-none">
          <button
          id={isReviewPage?"page9_btn_report":"page6_btn_report"}
            onClick={() => {
              !homeWorthval ? setErrVal('Field is required') : setopenComponent(4);
              document.getElementById("view")?.scrollIntoView()
            }}
            className="bg-brand select-none rounded-full font-sans text-lg	font-bold text-white pl-5 pr-5 pt-3 pb-3 sm:pl-10 sm:pr-10 sm:pt-4 sm:pb-4 mt-8 hover:bg-white hover:text-brand hover:border-brand hover:border-2"
          >
            Keep building my reports
          </button>
        </div>
      </div>
    </>
  );
};

export default FivePageCompoThree;
