import bg from '../../../../assets/BG.png';
import CountUp from 'react-countup';

interface CardProps {
  heading: string;
  value?: number;
}

function Card({ heading, value }: CardProps) {
  return (
    <div className="p-2 w-80 rounded-3xl relative text-white flex justify-center font-raleway transition ease-in-out delay-150 hover:-translate-y-1  hover:scale-110 duration-300">
      <img src={bg} alt=".." className="rounded-3xl" />
      <div className="absolute top-3 text-center text-2xl px-7 py-10 font-semibold h-32">
        {heading}
      </div>
      <div className="absolute top-2/4 flex flex-col items-center gap-4">
        <h1 className="text-2xl">Rates as low as</h1>
        <h1 className="font-bold text-4xl">
          {value && <CountUp end={value} decimals={2} suffix="%" enableScrollSpy />}
        </h1>
      </div>
    </div>
    // <div>
    //   <div
    //     // onClick={() => {
    //     //     setopenComponent(value)
    //     //     onClick()
    //     //   }}
    //     className="flex flex-col cursor-pointer justify-center text-center m-5 flex-wrap border-neutral-200 sm:border-[8px] border-[4px] rounded-3xl border-solid hover:shadow-md font-raleway  md:w-80  transition ease-in-out delay-150 hover:-translate-y-1 hover:translate-x-1 hover:scale-110 duration-300 bg-cardImage">
    //     <div className="rounded-t-2xl bg-brand text-white text-2xl px-7 py-10 font-semibold h-32 ">
    //       {heading}
    //     </div>
    //     <div className="flex flex-col gap-4 my-20 ">
    //       <h1 className='text-2xl'>Rates as low as</h1>
    //       <h1 className='font-bold text-4xl'>{value} <span className='text-brand'>%</span></h1>
    //     </div>
    //   </div>
    // </div>
  );
}

export default Card;
